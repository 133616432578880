<template>
	<tab>
		<template #title>
			Process indicators
		</template>
		<template #subtitle>
			{{ activeIndicator ? activeIndicator.name : ''}}
		</template>

		<template #content>
			<ChartData
				:keys="keys"
				:data-sources="dataSources"
				@setActiveModal="setActiveModal"
				@onAdjustFilters="setFiltersModal(true)"
				@openAlert="handleOpenAlert"
			>
				<template #chart>
					<component :is="activeChart" />
				</template>

				<template #countryFilter>
					<CountriesMultiselect
						:all-africa="true"
						:query-params="countryQueryParams"
						v-model="paiFilters.countries"
					/>
				</template>

				<template #criteriaFilter>
					<div class="tab-section__content__sidebar__navigation">
						<ul class="ul">
							<li
								v-for="(indicator, index) in indicators"
								:key="indicator.id"
								:class="{
									active: paiFilters.indicator === indicator.slug
								}"
								:style="index === 0 ? 'margin-bottom: 20px' : ''"
								@click="paiFilters.indicator = indicator.slug"
							>
								<span>
									<img src="@/assets/imgs/icons/icon-arrow-point.svg" alt="" />
								</span>
								<VLink size="small" :underline="true" @click.prevent>
									{{ indicator.name }}
								</VLink>
							</li>
						</ul>
					</div>
				</template>
			</ChartData>

			<component
				:is="modal"
				ref="modal"
				:indicator="paiFilters.indicator"
				:errors="errors"
				@close="setActiveModal(null)"
				@onSubmit="handleModalSubmit"
				@onPreview="handlePreview"
				v-if="modal"
			/>

			<portal to="pai" v-if="filtersModal">
				<PAIFilterModal @close="setFiltersModal(false)" />
			</portal>
		</template>
	</tab>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import debounce from 'lodash/debounce';

import {
	SAVE_VISUAL,
	FETCH_PAI_DATA,
	FETCH_PAI_INDICATORS,
	FETCH_PAI_DATA_SOURCES
} from '@/store/actions.type';

import {
	FINISH_LOADING,
	START_LOADING
} from '@/store/mutations.type';

import pai from '@/mixins/pai';
import Errors from '@/helpers/Errors';
import tooltipAlert from '@/mixins/tooltip-alert';
import PAIFilterModal from '@/modals/PAIFilterModal';
import ChartData from '@/pages/LandingPage/layout/ChartData';
import DataVisualViewModal from '@/modals/DataVisualViewModal';
import CustomizeDownloadModal from '@/modals/CustomizeDownloadModal';
import BarChart from '@/pages/LandingPage/sections/PolicyAdvocacyIndex/BarChart';
import BubbleChart from '@/pages/LandingPage/sections/PolicyAdvocacyIndex/BubbleChart';

export default {
	name: 'PolicyAdvocacyIndex',
	mixins: [pai, tooltipAlert],
	components: {
		BarChart,
		ChartData,
		BubbleChart,
		PAIFilterModal,
		DataVisualViewModal,
		CustomizeDownloadModal
	},
	data() {
		return {
			modal: null,
			filtersModal: false,
			errors: new Errors()
		};
	},
	computed: {
		...mapGetters(['paiFilters', 'loading']),
		...mapState({
			countries: state => state.app.countries,
			indicators: state => state.pai.indicators,
			dataSources: state => state.pai.dataSources
		}),
		activeChart() {
			if (!this.paiFilters.indicator) return null;

			return this.paiFilters.indicator === 'composite-score'
				? 'BubbleChart'
				: 'BarChart';
		},
		countryQueryParams() {
			return {
				graph: 'pai'
			};
		},
		keys() {
			return this.paiFilters.countries.map(country => {
				return {
					id: country.id,
					name: country.name,
					image: `flags/${country.slug}.png`
				};
			});
		},
        activeIndicator() {
            return this.indicators.find(indicator => indicator.slug === this.paiFilters.indicator);
        }
	},
	watch: {
		paiFilters: {
			handler: debounce(function() {
				this.fetchData();
			}, 700),
			deep: true
		},
		countries: {
			handler: 'setPreselectedCountries',
			immediate: true,
			deep: true
		}
	},
	destroyed() {
		this.paiFilters.countries = [];
		this.paiFilters.indicator = null;
	},
	mounted() {
		this.setData();
		this.fetchIndicators();
	},
	methods: {
		async fetchData() {
			this.$store.commit(START_LOADING);
			const params = this.setPaiRouterParams(this.paiFilters);
			const cleanParams = this.clearParams(params);

			await this.$store.dispatch(FETCH_PAI_DATA, cleanParams);
			await this.$store.dispatch(FETCH_PAI_DATA_SOURCES, cleanParams);
			this.$store.commit(FINISH_LOADING);
		},
		async submitVisualDataModalForm(form) {
			try {
				form = {
					...form,
					type: 'pai',
					filters: this.paiFilters
				};

				this.setActiveModal(null);
				await this.$store.dispatch(SAVE_VISUAL, form);
				this.$toastr.s('Success', 'Successfully saved visual');
			} catch ({ response: { data } }) {
				this.errors.record(data);
			}
		},
		setFiltersModal(value) {
			this.filtersModal = value;
		},
		handleModalSubmit(form, additional = null) {
			if (this.modal === 'CustomizeDownloadModal') {
				this.submitPaiDownloadModalForm(form, additional);
				return;
			}

			this.submitVisualDataModalForm(form);
		},
		handlePreview(form, additional = null) {
			const previewURL = this._generatePreviewURL(form, additional);

			window.open(previewURL, '_blank');
		},
		// ToDo Refactor
		setData() {
			const queryParams = this.$route.query;

			this.paiFilters.indicator = queryParams.indicator
				? queryParams.indicator
				: 'composite-score';

			if (queryParams.allAfrica)
				this.paiFilters.countries.push({
					id: 'all-africa',
					name: 'All Africa',
					slug: 'all-africa'
				});
		},
		setActiveModal(modal) {
			this.modal = modal;

			if (!modal) return;

			this.$nextTick(() => {
				const title = `Process indicators: ${
					this.indicators.find(
						indicator => indicator.slug === this.paiFilters.indicator
					).name
				}`;

				this.$refs.modal.form.title = title;
				this.$refs.modal.title = title;
			});
		},
		setPreselectedCountries() {
			if (!this.countries.length) return;
			this.setCountriesQueryParams();

			if (this.paiFilters.countries.length) return;
			this.paiFilters.countries = [
				{
					id: 'all-africa',
					name: 'All Africa',
					slug: 'all-africa'
				},
				...this.countries
			];
		},
		setCountriesQueryParams() {
			const queryParams = this.$route.query;

			if (queryParams.countries) {
				const countries =
					queryParams.countries && typeof queryParams.countries == 'object'
						? queryParams.countries
						: [queryParams.countries];

				this.paiFilters.countries = this.countries.filter(
					country =>
						countries.includes(String(country.id)) ||
						countries.includes(country.id)
				);

				const allAfrica = countries.includes('all-africa');

				if (allAfrica) {
					this.paiFilters.countries.push({
						id: 'all-africa',
						name: 'All Africa',
						slug: 'all-africa'
					});
				}
			}
		},
		fetchIndicators() {
			this.$store.dispatch(FETCH_PAI_INDICATORS);
		},
		handleOpenAlert(title, slug) {
			this.openInfoAlert(
				title,
				`${!slug.includes('country') ? 'pai-' : ''}` + slug
			);
		}
	}
};
</script>
